//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
  color: $ally-black;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
  overflow-x: hidden;
}

.logo {
  z-index: 10;
}

// sizing classes
.resize-animation-stopper {
  animation: none !important;
  transition: none !important;
}
.vh-100 {
  height: 100vh;
}

// coloring classes
.bg-magenta {
  background-color: $ally-magenta;
}
.bg-mint {
  background-color: $ally-mint;
}
.bg-yellow {
  background-color: $ally-yellow;
}
.bg-white {
  background-color: $ally-white;
}

.section {
  position: relative;

  .banner-leaddb {
    bottom: 7rem;
    position: absolute;
    width: 100%;
  }
}

.popover {
  font-family: Arial, sans-serif;
  border-radius: 0;
  --bs-popover-max-width: 400px;
}
.popover-body {
  font-size: 25px;
  text-align: center;
}

//---------------------------------------------
// 3.Components
//---------------------------------------------
@import "components/cookies";
@import "components/nav";
@import "components/links";
@import "components/header";
@import "components/video";
@import "components/buttons";
@import "components/cards";
@import "components/modals";
@import "components/marquee";
@import "components/footer";
