﻿:root {
  --gap: 3rem;
  --duration: 146s;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));
}

.marquee {
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}

.marquee__group {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
  animation: marquee 73s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(var(--scroll-start));
  }
  to {
    transform: translateX(var(--scroll-end));
  }
}

/* Element styles */
.marquee img {
  display: grid;
  place-items: center;
  fill: var(--color-text);
  background: var(--color-bg-accent);
  padding: calc(var(--size) / 10);
}
